import { NextPage } from "next";
import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
import Script from "next/script";
import { getUrlPath } from "utils/helpers";
import TrendingRoles from "components/NewerHomepage/TrendingRoles";
import WantToHire from "components/NewerHomepage/WantToHire";
import DownloadApna from "components/NewerHomepage/DownloadApna";
import NavbarV2, { NAVBAR_DESKTOP, NAVBAR_MOBILE } from "components/NavbarV2";
import Footer from "components/NewerHomepage/Footer";
import SEOV2 from "components/seoV2";
import TagManagerUtils from "utils/TagManager";
import PopularSearches from "components/NewerHomepage/PopularSearches";
import { isUserLoggedIn } from "utils";
import dynamic from "next/dynamic";
import LoaderAnimated from "assets/svgs/LoaderAnimated";
import TopBanner, { BANNER_ID } from "components/HomeNew/TopBanner";
import Image from "next/image";
import useTrackWebSession from "hooks/useTrackWebSession";
import { tickerData } from "components/NewerHomepage/TickerData";
import { useWindowResize } from "utils/hooks";
import FloatingNudge from "components/NewerHomepage/FloatingNudge";
import { useRouter } from "next/router";
import { fetchGeolocation } from "utils/GeoLocationUtil";
import useNearestJobLocation from "modules/candidate/profile/hooks/getNearestLocation";
import { setPrefilledLocation } from "utils/commonUtils";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import NaveenHeroImage from "assets/svgs/NaveenHeroImg";
import NaveenHeroImgMobile from "assets/svgs/NaveenMobileHero";
import { GTAG_EVENTS, GTag } from "utils/Gtag";
import useIntersectionObservers from "hooks/useIntersectionObservers";

const AnimatingSection = dynamic(
	() => import("components/NewerHomepage/AnimatingSection/AnimatingSection"),
	{
		ssr: false,
		loading: () => (
			<div className="flex  h-screen w-full items-center justify-center bg-[#190A28]">
				<LoaderAnimated className="!w-[160px]" />
			</div>
		),
	}
);

const Marquee = dynamic(() => import("components/Marquee/Marquee"), {
	ssr: false,
});

const metaTitle = "Search Jobs Online | Hire Candidates | Post a Job";
const metaDescription =
	"Apna is India's leading Professional Networking Platform. Users can Search for Jobs Online & Apply for Free. Employers can Post Job Vacancies & Hire Quality Candidates";
const pageName = "Main Home Page";

let defaultCity: any = {
	id: -1,
	name: "Anywhere in India",
	slug: "all",
};

const SearchComponent = dynamic(() => import("components/Search/Search"), {
	ssr: false,
});
const SearchComponentMobile = dynamic(() => import("components/Search/SearchMobile"), {
	ssr: false,
});
const JoinTheCommunity = dynamic(
	() => import("components/NewerHomepage/JoinTheCommunity"),
	{
		ssr: false,
	}
);
const PopularCompanies = dynamic(
	() => import("components/NewerHomepage/PopularCompanies/PopularCompanies"),
	{
		ssr: false,
	}
);

const Home: NextPage = () => {
	const [locationData, setLocationData] = useState({
		latitude: "",
		longitude: "",
	});
	const [isMobileView] = useWindowResize();
	useTrackWebSession(pageName);

	const CANDIDATE_LOGGED_IN_DEFAULT = "DEFAULT_LOGIN_STATE";
	const [isShowSearchModal, setShowSearchModal] = useState(false);

	const [isCandidateLoggedIn, setIsCandidateLoggedIn] = useState<string | boolean>(
		CANDIDATE_LOGGED_IN_DEFAULT
	);

	useEffect(() => {
		if (!!isUserLoggedIn() && isCandidateLoggedIn === CANDIDATE_LOGGED_IN_DEFAULT) {
			setIsCandidateLoggedIn(true);
		} else setIsCandidateLoggedIn(false);
	}, [!!isUserLoggedIn()]);
	const { setCitySearchObject, setTitleSearchObject } = useJobFeedStore();

	useEffect(() => {
		setTitleSearchObject(null);
		setCitySearchObject(null);
	}, []);

	useEffect(() => {
		if (isUserLoggedIn()) {
			fetchGeolocation(
				async (loc) => {
					const { lat, lng } = loc;
					setLocationData({
						latitude: lat.toString(),
						longitude: lng.toString(),
					});
				},
				(e) => {
					console.log(e);
				}
			);
		}
	}, []);

	const { data: fetchedLocationData } = useNearestJobLocation(locationData);
	if (fetchedLocationData?.data?.city) {
		defaultCity = fetchedLocationData.data.city;
		const prefilledLocation = defaultCity?.name;
		if (prefilledLocation) {
			setPrefilledLocation(prefilledLocation);
		}
	}

	useEffect(() => {
		import("../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Page View", {
				Source: pageName,
				page: getUrlPath(),
			});
		});

		TagManagerUtils.addDataLayer({
			Source: pageName,
		});
	}, []);

	const [upperHeight, setUpperHeight] = useState(0);

	// useLayoutEffect(() => {
	// 	calculateUpperHeight();
	// }, []);

	// This helps in closing the FilterSideBar Component on click of browser's back button
	const router = useRouter();
	useEffect(() => {
		router.beforePopState(({ as }) => {
			if (as !== router.asPath) {
				if (isShowSearchModal) {
					setShowSearchModal(false);
				}
			}
			return true;
		});

		return () => {
			router.beforePopState(() => true);
		};
	}, [router.query]);

	useEffect(() => {
		if (isShowSearchModal) {
			document.body.style.overflow = "hidden";
			// router.push("#");
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isShowSearchModal]);

	/* Calculate height of banner and navbar */
	// const calculateUpperHeight = () => {
	// 	const desktopNav = document.getElementById(NAVBAR_DESKTOP);
	// 	const mobileNav = document.getElementById(NAVBAR_MOBILE);
	// 	const banner = document.getElementById(BANNER_ID);

	// 	const bannerHeight = parseInt(getComputedStyle(banner).height, 10) || 0;
	// 	const headerHeight = Math.max(
	// 		parseInt(getComputedStyle(mobileNav).height, 10) || 0,
	// 		parseInt(getComputedStyle(desktopNav).height, 10) || 0
	// 	);
	// 	setUpperHeight(bannerHeight + headerHeight);
	// };

	return (
		<ThemeProvider theme={theme.apna}>
			<SEOV2
				title={metaTitle}
				description={metaDescription}
				metaTitle={metaTitle}
				image={undefined}
				pageType={undefined}
				publishedAt={undefined}
				slug={undefined}
			/>
			{/* <TopBanner
				onVisibilityChanged={() => calculateUpperHeight()}
				source="Main Home page"
				isBranding
			/> */}

			{/* Header */}
			<NavbarV2 containerClassName="!max-w-screen-xl !px-4" fromHomepage />

			{/* Hero section */}
			<div
				style={{
					height: isMobileView ? "auto" : `calc(100vh - ${upperHeight}px)`,
				}}
				className="relative flex max-h-[1180px] min-h-[615px] flex-col overflow-hidden bg-surface-disabled pb-12 md:min-h-[630px] md:py-10 md:pt-[20px]"
			>
				<div className="md:max-w-[820px]] absolute bottom-0 left-[30%] z-[2] w-full translate-x-[-30%] xs:right-0 md:bottom-0 md:bottom-[0px] md:left-[unset] md:right-[-80px] md:translate-x-[unset] lg:w-[48%]">
					<Image
						layout="responsive"
						width={820}
						height={598}
						objectFit="contain"
						objectPosition="bottom"
						className="relative w-full  md:ml-auto"
						src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/naveen-hero-homepage.png"
						alt="hero"
						priority
					/>
				</div>
				<div
					style={{
						top: "143px",
						width: "520px",
						height: "420px",
						left: "-200px",
						opacity: 0.6,
						position: "absolute",
						background:
							"radial-gradient(circle at center center, rgba(183, 90, 255, 0.12) 29.6%, rgb(21 193 217 / 0%) 66.76%)",
					}}
				/>
				<div
					className="hidden md:block"
					style={{
						zIndex: 1,
						top: "220px",
						width: "720px",
						height: "420px",
						right: "-100px",
						opacity: 1,
						position: "absolute",
						background:
							"radial-gradient(#156fd914 29.6%, rgba(21, 193, 217, 0) 65%)",
					}}
				/>

				{/* Content */}
				<div className="relative z-10 mx-auto flex h-full w-full max-w-screen-xl flex-col px-5 md:mb-[150px] md:block md:h-auto">
					<h1 className="mb-0 mt-10 text-[20px] font-bold text-green-500">
						INDIA’S #1 JOB PLATFORM
					</h1>
					<h2 className="mb-0 mt-3 max-w-[300px] text-[32px] font-bold leading-[48px] md:mt-6 md:max-w-none md:text-[56px] md:leading-[67px]">
						Your job search ends here
					</h2>
					<h2 className="mt-3 text-md font-[400] md:mb-[55px] md:mt-4 md:text-[24px]">
						Discover 50 lakh+ career opportunities
					</h2>

					{/* Search section */}
					{isMobileView ? (
						<SearchComponentMobile
							fromHomePage
							selectedCity={defaultCity}
							searchEntity={null}
							selectedArea={null}
							isShowSearchModal={isShowSearchModal}
							setShowSearchModal={setShowSearchModal}
						/>
					) : (
						<SearchComponent
							fromHomePage
							selectedCity={defaultCity}
							searchEntity={null}
							selectedArea={null}
						/>
					)}
				</div>

				{/* Carousel */}
				<Marquee
					className="absolute bottom-[64px] z-10 !hidden md:!flex"
					speed={180}
				>
					{tickerData.map((i) => (
						<div
							key={i.id}
							className="mr-8 flex w-[240px] shrink-0 items-center rounded-full bg-white p-3"
						>
							<span className="mr-2 h-[44px] w-[44px] shrink-0 overflow-hidden rounded-full bg-surface-disabled">
								<Image
									objectFit="cover"
									layout="fixed"
									height={44}
									width={44}
									priority
									alt={`testimony of ${i.name}`}
									src={i.photo_url}
								/>
							</span>
							<p className="m-0 text-sm font-semibold leading-[20px]">
								{i.text}
							</p>
						</div>
					))}
				</Marquee>
			</div>

			{/* Animating section */}
			{/* <AnimatingSection /> */}

			<PopularSearches />

			{/* Openings in popular companies */}
			{!isCandidateLoggedIn && <PopularCompanies />}

			{/* Trending roles */}
			{!isCandidateLoggedIn && <TrendingRoles />}

			{/* Join the community */}
			<JoinTheCommunity />

			{/* Download app */}
			<DownloadApna />

			{/* Want to hire */}
			{!isCandidateLoggedIn && <WantToHire />}

			{/* Trusted recruiters: It was once a part of the homepage */}
			{/* <TrustedRecruiters /> */}

			{/* Footer */}
			<Footer />

			{/* <FloatingNudge /> */}

			<Script
				id="organization"
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `
								{
								"@context": "https://schema.org",
								"@type": "Organization",
								"name": "ApnaTime",
								"url": "https://apna.co/",
								"logo": "https://apna.co/apna-time-icon.png",
								"sameAs": [
									"https://www.facebook.com/APNA-2386268451700180",
									"https://www.linkedin.com/company/26594389/admin/", 
									"https://twitter.com/apnahq",
									"https://www.instagram.com/apnahq/", 
									"https://www.youtube.com/channel/UCl-retoBiPxEqXMxxIhBt7A",
									"http://m.helo-app.com/al/FZxZFrjxr",
									"http://tiktok.com/@APNAJOBS",
									"https://sharechat.com/profile/apnajob"
								]
								}
         					 `,
				}}
			/>
			<Script
				id="software-application"
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `
						{
							"@context": "https://schema.org",
							"@type": "SoftwareApplication",
							"name": "Apna",
							"operatingSystem": "ANDROID",
							"applicationCategory": "BusinessApplication",
							"aggregateRating": {
								"@type": "AggregateRating",
								"ratingValue": "4.3",
								"ratingCount": "43051"
							},
							"offers": {
								"@type": "Offer",
                                "price": "0",
                                "priceCurrency": "INR"
							}
						}
					`,
				}}
			/>
		</ThemeProvider>
	);
};

export default Home;
